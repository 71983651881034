import React from 'react'
import { graphql } from 'gatsby';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Header from 'components/Header';
import SSContact from 'components/Sections/SSContact';


const ContactUsPage = ({
  data: {markdownRemark: { frontmatter } },
  pageContext,
}) => {
  
  return (
    <Layout>
      <SEO
        lang={pageContext.lang}
        title="Contact Us"
      />
      <Header rightGradient lang={pageContext.lang} />
      <SSContact page={frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactUs($lang: String!) {
    markdownRemark(frontmatter: { lang: { eq: $lang }, templateType: { eq: "contact-us"}}) {
      frontmatter {
        title
        label
        heading
        contact_info {
          address
          emails {
            email
          }
          phones {
            phone
          }
        }
      }
    }
  }
`

export default ContactUsPage;
